/* eslint-disable no-console */
import React, { useState, useEffect, useRef, useMemo } from "react";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import {
  DashboardLayout,
  OverlayDashboard,
  DownloadButtonWrapper,
} from "./styles";
import Waiting from "../Waiting";
import {
  setSelectedYear,
} from "../../redux/actions";
import isNull from "lodash/isNull";
import isUndefined from "lodash/isUndefined";
import YearDropdown from "../common/YearDropdown/YearDropdown";
import { TabContainer, TabView } from "../ChallengeDetailsV2/styles";
import { Container } from "../ChallengeDashboardV2/CompletedChallenge/styles";
import Overview from "./overview";
// import HoverButton from "../common/HoverButton";

import { /* AdminEventsAddCoachIcon, */ AdminEventsAddCoachIcon,  /* , AdminEventsAddCreateEventButton *//* , CreateEvent */ InitiativeDownloadIcon, AdminEventsAddCreateEventButton } from "../../utils/icons";
import { toast } from "react-toastify";
import { Dropdown } from "react-bootstrap";

import { EventEngagementData, GetPastEvents, DownloadOnDemandEventCSV } from "../../redux/constants/apiConstants";
import { fetchApi, getPermissionStatus } from "../../utils/methods";
import CompletedEvent from "./completedEvent";
import HoverButton from "../common/HoverButton";
import CSVList from '../AttendeesModal/CSVList';

const CompanyEvents = (props) => {
  const {
    networkError,
    history,
    yearList,
    selectedYearInfo,
    user,
    companyInfo,
    setSelectedYear,
    role,
    userPermissions,
  } = props;

  const [selectedTab, setSelectedTab] = useState(2);
  const [menuIsOpen, setMenuIsOpen] = useState(false);
  const dropdownRef = useRef();
  const [EngagementDataLoading,setEngagementDataLoading]=useState([]);
  const [EngagementData,setEngagementData]=useState([]);
  const [selectedTabId, setselectedTabId] = useState(1);
  const [pastEvents, setpastEvents] = useState([]);
  const [totalCount, settotalCount] = useState(1);
  const [PageNumber,setPageNumber] = useState(1);
  const [ondemandList, setOndemandList] = useState([]);
  const[printCSV,setPrintCSV]=useState(false);
  const [showLoadPast, checkPastevent]=useState(true);

  const CsvHeaders = [
    { label: "Event Name", key: "EVENTNAME" },
    { label: "Coach Name", key: "COACHNAME" },
    { label: "Video Url", key: "video_url"},
    { label: "Event ID", key: "EVENTID" },
    { label: "Event Tag", key: "EVENTTAG"},
    { label: "Last Updated", key: "Last Updated"},
  ];

  const printAction = () => {
    let data = [];
    ondemandList && ondemandList?.length > 0 && ondemandList.map((best) => {
      let obj = {};
      obj['EVENTNAME'] = best['event_name'];
      obj['COACHNAME'] = best['coach_name'];
      obj['EVENTID'] = best['event_id'];
      obj['EVENTTAG']=best['event_tag']
      obj['video_url']=best['video_url']
      obj['Last Updated']=best['Last Updated']
      data.push(obj);
    });
    return data;
  };


  useEffect(() => {
    setSelectedTab(2);
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  useEffect(()=>{
    GetEventsEngagement(companyInfo?.id,selectedYearInfo);
    GetCompletedEvents(PageNumber,50,companyInfo?.id,selectedYearInfo);
    FetchOndemandList()
    if(history.location.pathname === "/company/events"){
      setselectedTabId(1)
    }else if(history.location.pathname === "/company/events/completed"){
      setselectedTabId(2)
    }
    // setselectedTabId(1)
  },[])


  const GetEventsEngagement = async(companyId, year)=>{
    setEngagementDataLoading(true);
    const apiUrl = EventEngagementData + "/" + companyId + "/" + year;
    try{
      const res = await fetchApi(apiUrl,"GET");
      if(res.status === "success"){
        setEngagementDataLoading(false);
        setEngagementData(res?.data);
      }else{
        toast.error(res.error)
        setEngagementDataLoading(false);

      }
    }catch(error){
      window.console.log(error)
    }
  }

  const FetchOndemandList = async()=>{
    const apiUrl = DownloadOnDemandEventCSV ;
    try{
      const res = await fetchApi(apiUrl,"GET");
      if(res.status === "success"){
        setOndemandList(res?.data[0]);
      }else{
        toast.error(res.error)
      }
    }catch(error){
      window.console.log(error)
    }
  }

  const memoizedOverviewProps = useMemo(() => ({
    history,
    user,
    EngagementData: EngagementData,
    EngagementDataLoading: EngagementDataLoading,
    companyId: companyInfo?.id,
    role
  }), [history, user, EngagementData, EngagementDataLoading, companyInfo?.id,role]);

  const MemoizedOverview = useMemo(() => React.memo(Overview), []);


  const GetCompletedEvents = async(pageNumber,limit,companyId, year)=>{
    // window.console.log("HEU")
    // setEngagementDataLoading(true);
    const apiUrl =  `${GetPastEvents}/${pageNumber}/${limit}/${companyId}/${year}` 
    window.console.log("apiurl", apiUrl)
    try{
      const res = await fetchApi(apiUrl,"GET");
      window.console.log("res,res")
      if(res.status === "success"){
        setpastEvents([...pastEvents,...res.data.response]);
        settotalCount(res?.data?.count || 1000);
        setPageNumber(pageNumber)
        checkPastevent(false)
      }else{
        toast.error(res.error)
        // setEngagementDataLoading(false);

      }
    }catch(error){
      window.console.log(error)
    }
  }

  const handleClickOutside = (event) => {
    if (dropdownRef?.current && !dropdownRef?.current?.contains(event?.target)) {
      setMenuIsOpen(false);
    }
  };

  const toggleMenu = () => {
    setMenuIsOpen((prevMenuIsOpen) => !prevMenuIsOpen);
  };

  const updateDropdown = (year) => {
    setSelectedYear(year);
    GetEventsEngagement(companyInfo?.id,year)
    GetCompletedEvents(PageNumber,50,companyInfo?.id,year);
    window.console.log("selectedTab", selectedTab)
    // if (selectedTab === 2) {
    //   GetEventsEngagement(companyInfo?.id,year,)
    // } else if(selectedTab === 1){
    //   GetCompletedEvents(PageNumber,50,1,selectedYearInfo);

    // }
  };

  const showPastEvents = (path) => {
    history.push(path);
  };

  if (networkError || isNull(user) || isUndefined(user)) {
    return <Waiting />;
  }

  const createEventPermission = getPermissionStatus(
    "Create event",
    userPermissions
  );
  // const updateEventPermission = getPermissionStatus(
  //   "Update event",
  //   userPermissions
  // );
  // const addOrRemoveUserPermission = getPermissionStatus(
  //   "Add or Remove user",
  //   userPermissions
  // );
  // const printEventCsvPermission = getPermissionStatus(
  //   "Print event CSV",
  //   userPermissions
  // );

  const loadMorePastEvents = (pageNumber) => {

    GetCompletedEvents(pageNumber,50,companyInfo?.id,selectedYearInfo);
    setPageNumber(pageNumber)
    
  };

  const downloadEnrolledList = (bool) => {
    setPrintCSV(bool);
  };
  
  return (
    <Container>
      <div className="mainContainer">
        <OverlayDashboard>
          <DashboardLayout>
            <TabContainer marginBottom="20px">
              <TabView
                width
                padding
                active={selectedTabId === 1}
                onClick={() =>{showPastEvents("/company/events");setselectedTabId(1)}}
              >
                {"Overview"}
              </TabView>
              <TabView
                width
                padding
                active={selectedTabId === 2}
                onClick={() =>
                {showPastEvents("/company/events/completed");setselectedTabId(2)}
                }
              >
                {" "}
                {"Completed"}
              </TabView>
              {/* <TabView
                width
                padding
                active={selectedTabId === 3}
                onClick={() =>
                  showPastEvents("/company/challenges/categories")
                }
              >
                {" "}
                {"Library"}
              </TabView> */}
              <DownloadButtonWrapper isEvent={true}>
                <YearDropdown
                  refOutside={dropdownRef}
                  toggleMenu={toggleMenu}
                  background={"white"}
                  title={
                    selectedYearInfo && (
                      <div
                        className="title"
                        style={{
                          display: "flex",
                          justifyContent: yearList?.length > 1 ? "space-between" : "center",
                          width: "100%",
                          fontFamily: "Rubik-Medium",
                          color: "#007AB1",
                          fontSize: "16px",
                          lineHeight: "20px",

                        }}
                      >
                        <div>{selectedYearInfo}</div>
                        {yearList?.length > 1 && (<div
                          style={{
                            display: "flex",
                            alignItems: "center",
                            width: "16px",
                          }}
                        >
                          <svg
                            width="10"
                            height="16"
                            viewBox="0 0 10 16"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg"
                            style={{
                              transform: menuIsOpen ? "rotate(90deg)" : "rotate(90deg)",
                            }}
                          >
                            <path
                              d="M10 8.00003C10 8.28678 9.87995 8.5735 9.64036 8.79212L2.09719 15.6718C1.61736 16.1094 0.839379 16.1094 0.359734 15.6718C-0.119911 15.2343 -0.119911 14.5249 0.359734 14.0872L7.03435 8.00003L0.359967 1.91279C-0.119678 1.47515 -0.119678 0.765811 0.359967 0.328387C0.839612 -0.109461 1.61759 -0.109461 2.09743 0.328387L9.6406 7.20793C9.88023 7.42666 10 7.71338 10 8.00003Z"
                              fill="#007AB1"
                            />
                          </svg>
                        </div>)}
                      </div>
                    )
                  }
                  data={yearList}
                  onSelect={updateDropdown}
                  style={{
                    background: "white",
                    lineHeight: "20px",
                    color: "rgba(0, 92, 135, 0.6)",
                    padding: "0px",
                    border: "1px solid #005C874D",
                    marginRight:"6px",
                    marginTop: "10px",
                    marginBottom: "10px"
                  }}
                />

                {/* ******************* Hidden for testing ******************* */}
                {/* <div style={{marginRight:"8px"}}><HoverButton svgPath={CreateEvent()} activeColor={"#007AB1"} fillOpacity={1} title={"Create Event"} onClick={()=>history.push("/events/create")}/></div> */}

                {/* ******************* Hidden for testing except Create Coach ******************* */}
                {role === "ADMIN" ? (<div style={{ marginRight: "6px" }} >
                  <HoverButton svgPath={AdminEventsAddCoachIcon()} activeColor={"#007AB1"} fillOpacity={1} title={"Create Coach"} onClick={() => history.push("/company/events/create-coach")} />
                </div>):null}
                {role === "ADMIN" ? (<div style={{ marginRight: "6px" }} >
                  <HoverButton width={"40px"} height={"40px"} fillOpacity={1} svgPath={InitiativeDownloadIcon()} activeColor={"#007AB1"} title={"Download On-Demand Events"} onClick={()=>downloadEnrolledList(true)} />
                </div>):null}

                <div className="create-events" style={{padding: "0px"}}>
                  <Dropdown>
                    <Dropdown.Toggle>
                      {/* <div style={{background:"rgba(0, 92, 135, 0.05)",padding:"8px",borderRadius:"4px"}}> */}
                      {
                        <HoverButton svgPath={AdminEventsAddCreateEventButton()} activeColor={"#007AB1"} fillOpacity={1} title={"Create Events"} />
                      }
                      {/* {AdminEventsAddCreateEventButton()} */}
                      {/* </div> */}
                      {/* <HoverButton svgPath={AdminEventsAddCreateEventButton()} activeColor={"#007AB1"} fillOpacity={1} title={"Create Events"} />     */}
                    </Dropdown.Toggle>
                    <Dropdown.Menu>
                      <Dropdown.Item onClick={() => history.push(`/company/events/create`)} >
                        Create Live Events
                      </Dropdown.Item>
                      {role === "ADMIN" &&<><hr/>
                        <Dropdown.Item onClick={() => history.push(`/company/events/create-ondemand`)} >
                        Create On-Demand Events
                        </Dropdown.Item>
                        <hr/>
                        <Dropdown.Item onClick={() => history.push(`/company/events/create-lunch-learns`)}>Create Lunch and Learns Events</Dropdown.Item>
                      </>}
                    </Dropdown.Menu>
                  </Dropdown>
                </div>
                
              </DownloadButtonWrapper>
            </TabContainer>
            {selectedTabId === 1 && (
              <MemoizedOverview {...memoizedOverviewProps} />
            )}
            {selectedTabId === 2 && (
              <CompletedEvent
                history={history}
                pastEvents={pastEvents}
                loadMoreEvents={(pageNumber) => loadMorePastEvents(pageNumber)}
                totalCount={totalCount}
                role={role}
                isAdmin={createEventPermission}
                fetchPastEvents={GetCompletedEvents}
                // pageNumber={this.state.pageNumber}
                showLoadPast={showLoadPast}


              />
            )}
            {printCSV &&
              <CSVList
                Title={"List of All On-Demand Events"}
                removeCSVData={downloadEnrolledList}
                headers={CsvHeaders}
                data={printAction()}
              />
            }
          </DashboardLayout>
        </OverlayDashboard>
      </div>
    </Container>
  );
};

CompanyEvents.defaultProps = {
  companyInfo: { id: 1, name: "" },
};

CompanyEvents.propTypes = {
  networkError: PropTypes.string,
  companyInfo: PropTypes.object.isRequired,
  role: PropTypes.string.isRequired,
  history: PropTypes.object,
  selectedYearInfo: PropTypes.number,
  yearList: PropTypes.array,
  setSelectedYear: PropTypes.func,
  user: PropTypes.object,
  challengesData: PropTypes.array,
  t: PropTypes.func,
  selectedTabId: PropTypes.number,
  pastEvents: PropTypes.array,
  totalCount: PropTypes.number,
  userPermissions: PropTypes.array,
};

const mapStateToProps = (state) => ({
  networkError: state.companyDashboard.networkError,
  selectedYearInfo: state.companyDashboard.selectedYearInfo,
  user: state.profileData.user,
  pastEvents: state.events.pastEvents,
  totalCount: state.events.totalCount,
  role: state.auth.role,
  userPermissions: state.profileData.userPermissions,
});

const mapDispatchToProps = (dispatch) => ({
  setSelectedYear: (year) => dispatch(setSelectedYear(year)),
});

export default connect(mapStateToProps, mapDispatchToProps)(CompanyEvents);
